<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        @change="wizardChange"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="d-none d-lg-flex wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/General/User.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Introduction
                  </h3>
                  <div class="wizard-desc">
                    Setup Your trustee
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper d-flex flex-row align-items-center">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x d-flex">
                    <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </div>
                <div class="wizard-label d-flex flex-grow-1">
                  <h3 class="wizard-title">
                    Trustee 1
                  </h3>
                  <div class="wizard-desc">
                    {{ g1Label }}
                  </div>
                </div>
                <div class="validation-icon d-flex">
                  <i v-if="form.g1.isValid" class="fas fa-check"></i>
                </div>
              </div>
            </div>
            <div class="wizard-step" href="#" data-wizard-type="step">
              <div class="wizard-wrapper d-flex flex-row align-items-center">
                <div class="wizard-icon d-flex">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </div>
                <div class="wizard-label d-flex flex-grow-1">
                  <h3 class="wizard-title">
                    Trustee 2
                  </h3>
                  <div class="wizard-desc">
                    {{ g2Label }}
                  </div>
                </div>
                <div class="validation-icon d-flex">
                  <i v-if="form.g2.isValid" class="fas fa-check"></i>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper d-flex flex-row align-items-center">
                <div class="wizard-icon d-flex">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/Communication/Shield-user.svg" />
                  </span>
                </div>
                <div class="wizard-label d-flex flex-grow-1">
                  <h3 class="wizard-title">
                    Trustee 3
                  </h3>
                  <div class="wizard-desc">
                    {{ g3Label }}
                  </div>
                </div>
                <div class="validation-icon d-flex">
                  <i v-if="form.g3.isValid" class="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/Communication/Sending mail.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Nomination Message
                  </h3>
                  <div class="wizard-desc">
                    Setup Nomination Message
                  </div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/media/svg/icons/General/Like.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    Confirm &amp; Send
                  </h3>
                  <div class="wizard-desc">
                    Confirm details and send
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-1 col-xxl-10">
              <!--begin: Wizard Step 1-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <h1 class="mb-10 font-weight-bold text-dark">
                  Nominate 3 People To Be Your Trustees
                </h1>
                <p>
                  Your trustees are the people who will confirm your passing on. After adding the details of three
                  people, they will each receive an email, notifying them of the nomination. If they accept the
                  nomination, they will receive a pin that they must keep in a safe place.
                </p>
                <p>
                  When you pass on, any one of them can enter their secret key in this platform. This will notify the
                  other two trustees to do the same. Then, once all your trustees have entered their pins, your messages
                  will be scheduled for dispatch.
                </p>

                <p>
                  Please note that your trustees will never gain access to your account. They are simply there to
                  confirm your passing on and activate your scheduled messages {{ appName }}.
                </p>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <form id="kt_form_g1" class="form">
                  <h4 class="mb-10 font-weight-bold text-dark">Trustee 1: {{ form.g1.name }}</h4>
                  <div class="form-group">
                    <label>Nickname</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      name="g1name"
                      placeholder="Nickname"
                      v-model="form.g1.name"
                    />
                    <span class="form-text text-muted">Enter Trustee 1's name and surname</span>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Phone</label>
                        <input
                          type="tel"
                          class="form-control form-control-solid form-control-lg"
                          name="g1phone"
                          placeholder="phone"
                          v-model="form.g1.phone"
                        />
                        <span class="form-text text-muted">Please enter trustee 1's phone number.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control form-control-solid form-control-lg"
                          name="g1email"
                          placeholder="Email"
                          v-model="form.g1.email"
                        />
                        <span class="form-text text-muted">Please enter trustee 1's email address.</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Trustee 2: {{ form.g2.name }}</h4>
                <form class="form" id="kt_form_g2">
                  <div class="form-group">
                    <label>Nickname</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      name="g2name"
                      placeholder="Nickname"
                      v-model="form.g2.name"
                    />
                    <span class="form-text text-muted">Enter Trustee 1's name and surname</span>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Phone</label>
                        <input
                          type="tel"
                          class="form-control form-control-solid form-control-lg"
                          name="g2phone"
                          placeholder="phone"
                          v-model="form.g2.phone"
                        />
                        <span class="form-text text-muted">Please enter your phone number.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control form-control-solid form-control-lg"
                          name="g2email"
                          placeholder="Email"
                          v-model="form.g2.email"
                        />
                        <span class="form-text text-muted">Please enter your email address.</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Trustee 3: {{ form.g3.name }}</h4>
                <form id="kt_form_g3" class="form">
                  <div class="form-group">
                    <label>Nickname</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      name="g3name"
                      placeholder="Nickname"
                      v-model="form.g3.name"
                    />
                    <span class="form-text text-muted">Enter Trustee 3's name and surname</span>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Phone</label>
                        <input
                          type="tel"
                          class="form-control form-control-solid form-control-lg"
                          name="g3phone"
                          placeholder="phone"
                          v-model="form.g3.phone"
                        />
                        <span class="form-text text-muted">Please enter trustee 3's phone number.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          class="form-control form-control-solid form-control-lg"
                          name="g3email"
                          placeholder="Email"
                          v-model="form.g3.email"
                        />
                        <span class="form-text text-muted">Please enter trustee 3's email address.</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-5 font-weight-bold text-dark">
                  Nomination Message
                </h4>
                <p id="helpId" class="text text-small mb-10 ">
                  This message will be sent to your trustees. Please do not change the placeholders e.g %%nickname%%.
                  They will be replaced with real content when the message is sent.
                </p>
                <div class="form-group">
                  <vue-editor output="html" :editorToolbar="quillToolbar" v-model="form.message"> </vue-editor>
                </div>
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Step 6-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Confirm Details and send
                </h4>
                <b-card-group>
                  <b-card header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <h6 class="mb-0 text text-center">Trustee 1 <br />{{ form.g1.name }}</h6>
                    </template>
                    <b-card-text>
                      <p><b>Email Address</b><br />{{ form.g1.email }}</p>
                      <p><b>Phone Number:</b><br />{{ form.g1.phone }}</p>
                    </b-card-text>
                    <b-button @click.stop.prevent="goto(2)" href="#" variant="outline-secondary">Edit</b-button>
                  </b-card>
                  <b-card header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <h6 class="mb-0 text text-center">Trustee 2 <br />{{ form.g2.name }}</h6>
                    </template>
                    <b-card-text>
                      <p><b>Email Address</b><br />{{ form.g2.email }}</p>
                      <p><b>Phone Number:</b><br />{{ form.g2.phone }}</p>
                    </b-card-text>
                    <b-button @click.stop.prevent="goto(3)" href="#" variant="outline-secondary">Edit</b-button>
                  </b-card>
                  <b-card header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <h6 class="mb-0 text text-center">Trustee 3 <br />{{ form.g3.name }}</h6>
                    </template>
                    <b-card-text>
                      <p><b>Email Address</b><br />{{ form.g3.email }}</p>
                      <p><b>Phone Number:</b><br />{{ form.g3.phone }}</p>
                    </b-card-text>
                    <b-button @click.stop.prevent="goto(4)" href="#" variant="outline-secondary">Edit</b-button>
                  </b-card>
                </b-card-group>
              </div>
              <!--end: Wizard Step 6-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit"
                    :class="apiBusy ? buttonSpinner : ''"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Submit
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Next Step
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { SETUP_TRUSTEES } from "@/core/services/store/trustees.module";

// quill editor
import { VueEditor } from "vue2-quill-editor";

// form validation
import FormValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
export default {
  name: "Wizard-2",
  components: {
    VueEditor
  },
  data: function() {
    return {
      form: {
        g1: {
          isValid: false,
          name: "",
          phone: "",
          email: ""
        },

        g2: {
          isValid: false,
          name: "",
          phone: "",
          email: ""
        },

        g3: {
          isValid: false,
          name: "",
          phone: "",
          email: ""
        },
        /* eslint-disable */
        message: `<p>Hello %%nickname%%</p><p>I have nominated you to become a trustee for my account with andLastly.io™. This platform allows us to deliver messages to loved ones after we have passed on.  The messages may include important instructions to help wrap up our affairs or simple letters of goodbye to friends and loved ones.</p><p>If you accept to be a trustee, this email address will be stored on ${this.appName}.  Then, when I pass away, you will be required to enter your email address to retrieve the key to dispatch all the messages I have scheduled.</p><p>I know this may come as a surprise.  Call me if you have any questions.</p><p>Here's the link to accept the trusteeship</p><p>%%link%%</p><p>%%yourname%%</p>`
        /* eslint-enable*/
      }
    };
  },
  computed: {
    ...mapGetters(["appName", "currentUser", "apiBusy", "buttonSpinner", "quillToolbar"]),

    g1Label() {
      return this.form.g1.name ? this.form.g1.name : "Setup your first trustee";
    },
    g2Label() {
      return this.form.g2.name ? this.form.g2.name : "Setup your second trustee";
    },
    g3Label() {
      return this.form.g3.name ? this.form.g3.name : "Setup your third trustee";
    },
    isValid() {
      if (this.form.g1.isValid && this.form.g2.isValid && this.form.g3.isValid) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Wizard", route: "wizard-1" }, { title: "Wizard-2" }]);

    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // setupValidation
    const form_g1 = KTUtil.getById("kt_form_g1");
    const form_g2 = KTUtil.getById("kt_form_g2");
    const form_g3 = KTUtil.getById("kt_form_g3");

    this.fv1 = FormValidation(form_g1, {
      fields: {
        g1name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        },
        g1phone: {
          validators: {
            notEmpty: {
              message: "Phone number is required"
            },
            numeric: {
              message: "Only numeric values are allowed"
            }
          }
        },
        g1email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "Please enter a valid email address"
            },
            different: {
              compare: this.currentUser.user_email,
              message: "You cannot add your own email address"
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap({})
      }
    });

    this.fv2 = FormValidation(form_g2, {
      fields: {
        g2name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        },
        g2phone: {
          validators: {
            notEmpty: {
              message: "Phone number is required"
            },
            numeric: {
              message: "Only numeric values are allowed"
            }
          }
        },
        g2email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "Please enter a valid email address"
            },
            different: {
              compare: this.currentUser.user_email,
              message: "You cannot add your own email address"
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap({})
      }
    });

    this.fv3 = FormValidation(form_g3, {
      fields: {
        g3name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        },
        g3phone: {
          validators: {
            notEmpty: {
              message: "Phone number is required"
            },
            numeric: {
              message: "Only numeric values are allowed"
            }
          }
        },
        g3email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "Please enter a valid email address"
            },
            different: {
              compare: this.currentUser.user_email,
              message: "You cannot add your own email address"
            }
          }
        }
      },

      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap({})
      }
    });

    // Change event
    this.wizard.on("change", wizardObj => {
      this.wizardChange(wizardObj);
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    goto(step) {
      this.wizard.goTo(step);
    },
    wizardChange(wizard) {
      // do validation
      if (wizard.currentStep == 2) {
        this.fv1.validate().then(status => {
          if (status == "Valid") {
            this.form.g1.isValid = true;
          }
        });
      }

      if (wizard.currentStep == 3) {
        this.fv2.validate().then(status => {
          if (status == "Valid") {
            this.form.g2.isValid = true;
          }
        });
      }

      if (wizard.currentStep === 4) {
        this.fv3.validate().then(status => {
          if (status == "Valid") {
            this.form.g3.isValid = true;
          }
        });
      }
    },
    submit: function() {
      if (this.isValid === true) {
        this.$store
          .dispatch(SETUP_TRUSTEES, this.form)
          .then(() => {
            const toast_title = `Trustees created`;
            const toast_content = `Your trustees were successfully registered and an invitation email was sent to each of them.`;

            this.$bvToast.toast(toast_content, {
              title: toast_title,
              autoHideDelay: 10000,
              appendToast: true,
              variant: "success"
            });

            // redirect
            this.$router.push({ name: "trustees" });
          })

          .catch(error => {
            Swal.fire({
              text: error.message,
              icon: "error",
              buttonsStyling: false,
              heightAuto: false,
              showCancelButton: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn font-weight-bold btn-light-danger",
                cancelButton: "btn font-weight-bold btn-default"
              }
            });
          });
      } else {
        Swal.fire({
          text: "Please make sure that all the trustees' details are filled in correctly",
          icon: "error",
          buttonsStyling: false,
          heightAuto: false,
          showCancelButton: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light-danger",
            cancelButton: "btn font-weight-bold btn-default"
          }
        });
      }
    }
  }
};
</script>
